<template>
  <div class="feedback-list">
    <!-- 筛选表单，筛选项：处理状态 -->
    <div class="filter-form">
      <a-form-model :model="form" layout="inline">
        <a-form-model-item label="处理状态：">
          <a-select
            placeholder="请选择"
            style="width: 180px"
            v-model="form.is_processed"
          >
            <a-select-option :value="true">已处理</a-select-option>
            <a-select-option :value="false">未处理</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onSearchClick"> 搜索 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 表格，表格列：序号、反馈内容、反馈人、处理状态、处理结果、处理人、处理时间、操作 -->
    <div class="table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record) => record._id"
        @change="handleTableChange"
      >
        <!-- is_processed tag -->
        <template slot="is_processed" slot-scope="text, record">
          <a-tag v-if="record.is_processed" color="green">已处理</a-tag>
          <a-tag v-else color="red">未处理</a-tag>
        </template>
        <!-- operation 处理 -->
        <template slot="operation" slot-scope="text, record">
          <a-button
            v-if="!record.is_processed"
            type="link"
            @click="handleProcessClick(record)"
          >
            处理
          </a-button>
          <a-button
            v-else
            type="link"
            @click="handleProcessClick(record)"
          >
            查看
          </a-button>
        </template>
      </a-table>
    </div>
    <!-- 反馈处理 -->
    <FeedbackDrawer ref="feedbackDrawer" @success="onFeedbackProcessed" />
  </div>
</template>

<script>
import FeedbackDrawer from '@/components/FeedbackDrawer.vue';

export default {
  name: 'FeedbackList',
  components: { FeedbackDrawer },
  data() {
    return {
      is_processed: undefined,
      loading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 80,
          align: 'center',
          customRender: (text, record, index) => {
            return (
              (this.pagination.current - 1) * this.pagination.pageSize + index + 1
            );
          },
        },
        {
          title: '反馈主题',
          dataIndex: 'theme',
        },
        {
          title: '反馈人',
          dataIndex: 'create_by',
          width: 200,
          align: 'center',
          customRender: (text, record) => {
            return `@${record.create_by.pi_username}`;
          },
        },
        {
          title: '反馈时间',
          dataIndex: 'create_at',
          width: 180,
          align: 'center',
          customRender: (text, record) => {
            return this.$dayjs(parseInt(record.create_at)).format(
              'YYYY-MM-DD HH:mm:ss'
            );
          },
        },
        {
          title: '处理状态',
          dataIndex: 'is_processed',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'is_processed' },
        },
        // {
        //   title: '处理结果',
        //   dataIndex: 'process_result',
        //   minWidth: 100,
        //   align: 'center',
        // },
        {
          title: '处理人',
          dataIndex: 'process_by',
          width: 120,
          align: 'center',
          customRender: (text, record) => {
            return record.process_by && record.process_by.username;
          },
        },
        // {
        //   title: '处理时间',
        //   dataIndex: 'process_at',
        //   width: 180,
        //   align: 'center',
        //   customRender: (text, record) => {
        //     return record.process_at && this.$dayjs(record.process_at).format('YYYY-MM-DD HH:mm:ss');
        //   }
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 180,
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      form: {
        is_processed: undefined,
      },
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleTableChange({ current }) {
      this.pagination.current = current;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      const { err, res } = await this.$api.feedback.list({
        pageIndex: this.pagination.current - 1,
        pageSize: this.pagination.pageSize,
        is_processed: this.form.is_processed,
      });

      if (!err) {
        this.list = res.result.list;
        this.pagination.total = res.result.total;
      }
      this.loading = false;
    },
    // 点击搜索按钮
    onSearchClick() {
      this.pagination.current = 0;
      this.fetchData();
    },
    handleProcessClick(record) {
      this.$refs.feedbackDrawer.open(record);
    },
    onFeedbackProcessed() {
      this.pagination.current = 0;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.feedback-list {
  .filter-form {
    margin-bottom: 16px;
  }
}
</style>
