<template>
  <div class="feedback-drawer">
    <a-drawer
      title="处理反馈"
      :visible="visible"
      :width="600"
      :destroyOnClose="true"
      @close="onClose"
    >
      <a-form-model :model="form" layout="vertical" :rules="rules">
        <a-form-model-item label="反馈主题：">
          {{ form.theme }}
        </a-form-model-item>
        <a-form-model-item label="反馈内容：">
          {{ form.message }}
        </a-form-model-item>
        <a-form-model-item label="反馈人：">
          {{ form.create_by && form.create_by.pi_username }}
        </a-form-model-item>
        <a-form-model-item label="反馈时间：">
          {{ $dayjs(parseInt(form.create_at)).format('YYYY-MM-DD HH:mm:ss') }}
        </a-form-model-item>
        <a-form-model-item label="处理状态：">
          <a-tag v-if="form.is_processed" color="green">已处理</a-tag>
          <a-tag v-else color="red">未处理</a-tag>
        </a-form-model-item>
        <a-form-model-item label="处理结果：" prop="process_result">
          <a-input
            v-if="!form.is_processed"
            type="textarea"
            :rows="4"
            placeholder="请输入处理结果"
            v-model="form.process_result"
          />
          <template v-else>
            {{ form.process_result }}
          </template>
        </a-form-model-item>
        <a-form-model-item v-if="form.is_processed" label="处理人：">
          {{ form.process_by && form.process_by.username }}
        </a-form-model-item>
        <a-form-model-item v-if="form.is_processed" label="处理时间：">
          {{ $dayjs(parseInt(form.process_at)).format('YYYY-MM-DD HH:mm:ss') }}
        </a-form-model-item>
        <a-form-model-item v-if="!form.is_processed">
          <a-button type="primary" :loading="loading" @click="onSubmitClick">
            提交处理
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'FeedbackDrawer',
  data() {
    return {
      visible: false,
      form: {
        _id: '',
        theme: '',
        message: '',
        create_by: null,
        create_at: null,
        is_processed: undefined,
        process_result: '',
      },
      rules: {
        process_result: [
          { required: true, message: '请输入处理结果', trigger: 'blur' },
        ],
      },
      loading: false,
    };
  },
  methods: {
    open(detail) {
      this.form = {
        ...detail,
        is_processed: detail.is_processed || false,
      };
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    async onSubmitClick() {
      this.loading = true;
      const { err, res } = await this.$api.feedback.process({
        _id: this.form._id,
        is_processed: this.form.is_processed,
        process_result: this.form.process_result,
      });
      if (!err) {
        this.$message.success(res.msg);
        this.form = {
          _id: '',
          message: '',
          create_by: null,
          create_at: null,
          is_processed: undefined,
          process_result: '',
        };
        this.visible = false;
        this.$emit('success');
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
